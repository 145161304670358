import { Stack, Typography as JoyTypography } from '@mui/joy';
import EthereumLogo from 'cryptocurrency-icons/svg/color/eth.svg?react';
import mapValues from 'lodash/fp/mapValues';
import type { Factory, ReactElement } from 'react';

import AploLogo from './aplo.svg?react';
import BinanceLogo from './binance.svg?react';
import BitgetLogo from './bitget.svg?react';
import BybiLogo from './bybit.svg?react';
import CoinBaseLogo from './coinbase.svg?react';
import CoinBaseBlueLetterCLogo from './coinBaseBlueLetterC.svg?react';
import CopperLogo from './copper.svg?react';
import { DefaultIPWhitelistingSection } from './DefaultIPWhitelistingSection.tsx';
import DeribitLogo from './deribit.svg?react';
import FalconXLogo from './falconx.svg?react';
import FireblocksLogo from './fireblocks.svg?react';
import FtxLogo from './ftx.svg?react';
import GateioLogo from './gateio.svg?react';
import HuobiLogo from './huobi.svg?react';
import KrakenLogo from './kraken.svg?react';
import KucoinLogo from './kucoin.svg?react';
import OKXLogo from './okx.svg?react';
import PhemexLogo from './phemex.svg?react';
import { SelectableIPAddresses } from './SelectableIPAddresses.tsx';
import SolanaLogo from './solana.svg?react';
import BitcoinLogo from './bitcoin.svg?react';
import DyDxLogo from './dydx.svg?react';
import UnknownVenueLogo from './unknownExchange.svg?react';
import type { ISecretsInput } from '../../generated/graphql.tsx';
import { commonBlack, commonWhite, neutralPlainDisabledColor } from '../../theme/colors';
import { formatLabelToName, normalizeParts } from '../formatter.utils';

export interface VenueField {
  label: string;
  name: keyof ISecretsInput;
  type?: string;
  optional?: boolean;
}

export interface VenueEntry {
  name: string;
  blockchain?: boolean;
  tooltip?: string;
  symbol: string;
  icon: () => ReactElement;
  iconBackground: string;
  extraFields?: VenueField[];
  disclaimer: Factory<unknown>;
  adminOnly?: boolean;
}

export const unknownVenueLabel = 'ex:unknown';
export const GENIE_VENUE = 'dpr:genie';
const unknownVenue: VenueEntry = {
  name: 'Unknown',
  symbol: 'N/A',
  icon: UnknownVenueLogo,
  iconBackground: neutralPlainDisabledColor,
  disclaimer: DefaultIPWhitelistingSection,
};

const formatLabelToSymbol = (name: Label): string => {
  if (!name) {
    return '';
  }

  const parts = normalizeParts(name);
  const lastPart = parts.at(-1);
  if (!lastPart) {
    throw new Error('Empty path');
  }

  return lastPart.toUpperCase();
};

export const createUnsupportedVenueEntry = (label: Label): VenueEntry => {
  return {
    ...unknownVenue,
    name: formatLabelToName(label),
    symbol: formatLabelToSymbol(label),
  };
};

export const rawVenues: Record<string, Omit<VenueEntry, 'disclaimer'> & { disclaimer?: VenueEntry['disclaimer'] }> = {
  'cex:binance': {
    name: 'Binance',
    symbol: 'BIN',
    icon: BinanceLogo,
    iconBackground: commonBlack,
    extraFields: [
      { label: 'API key', name: 'apiKey' },
      { label: 'Secret key', name: 'secret' },
    ],
    disclaimer: (): ReactElement => {
      return (
        <Stack spacing={1.5}>
          <JoyTypography color="neutral" level="body-md" maxWidth={500}>
            IP whitelisting is required to receive derivative information from the exchange. Without it we will be only
            able to track spots. Please whitelist the following IP addresses:
          </JoyTypography>
          <SelectableIPAddresses />
        </Stack>
      );
    },
  },
  'cex:binance-v2': {
    name: 'Binance',
    symbol: 'BIN',
    icon: BinanceLogo,
    iconBackground: commonBlack,
    adminOnly: true,
    extraFields: [
      { label: 'API key', name: 'apiKey' },
      { label: 'Secret key', name: 'secret' },
    ],
    disclaimer: (): ReactElement => {
      return (
        <Stack spacing={1.5}>
          <JoyTypography color="neutral" level="body-md" maxWidth={500}>
            IP whitelisting is required to receive derivative information from the exchange. Without it we will be only
            able to track spots. Please whitelist the following IP addresses:
          </JoyTypography>
          <SelectableIPAddresses />
        </Stack>
      );
    },
  },
  'cex:binanceus': {
    name: 'Binance US',
    symbol: 'BIN US',
    icon: BinanceLogo,
    iconBackground: commonBlack,
    extraFields: [
      { label: 'API key', name: 'apiKey' },
      { label: 'Secret key', name: 'secret' },
    ],
    adminOnly: true,
  },
  'cex:ftx': {
    name: 'FTX',
    symbol: 'FTX',
    icon: FtxLogo,
    iconBackground: commonWhite,
  },
  'cex:ftxus': {
    name: 'FTX US',
    symbol: 'FTX US',
    icon: FtxLogo,
    iconBackground: commonWhite,
  },
  'cex:coinbaseprime': {
    name: 'Coinbase Prime',
    symbol: 'Coinbase Prime',
    icon: CoinBaseLogo,
    iconBackground: '#0052FF',
    extraFields: [
      { label: 'Access key', name: 'apiKey' },
      { label: 'Signing key', name: 'secret' },
      { label: 'Passphrase', name: 'password', type: 'password' },
      { label: 'Service account id', name: 'accountId' },
    ],
  },
  'cex:coinbaseprime-v2': {
    name: 'Coinbase Prime',
    symbol: 'Coinbase Prime',
    icon: CoinBaseLogo,
    iconBackground: '#0052FF',
    adminOnly: true,
    extraFields: [
      { label: 'Access key', name: 'apiKey' },
      { label: 'Signing key', name: 'secret' },
      { label: 'Passphrase', name: 'password', type: 'password' },
      { label: 'Service account id', name: 'accountId' },
    ],
  },
  'cex:coinbasepro': {
    name: 'Coinbase Pro',
    symbol: 'Coinbase Pro',
    icon: CoinBaseLogo,
    iconBackground: commonBlack,
    extraFields: [
      { label: 'API key', name: 'apiKey' },
      { label: 'Secret key', name: 'secret' },
      { label: 'Password', name: 'password', type: 'password' },
    ],
  },
  'cex:coinbase': {
    name: 'Coinbase',
    symbol: 'Coinbase',
    icon: CoinBaseBlueLetterCLogo,
    iconBackground: commonBlack,
    extraFields: [
      { label: 'API key', name: 'apiKey' },
      { label: 'Secret key', name: 'secret' },
    ],
  },
  'cex:kraken': {
    name: 'Kraken',
    symbol: 'Kraken',
    icon: KrakenLogo,
    iconBackground: '#5741D9',
    extraFields: [
      { label: 'API key', name: 'apiKey' },
      { label: 'Secret key', name: 'secret' },
    ],
  },
  'cex:okx': {
    name: 'OKX',
    symbol: 'OKX',
    icon: OKXLogo,
    iconBackground: commonBlack,
    extraFields: [
      { label: 'Password', name: 'password', type: 'password' },
      { label: 'API key', name: 'apiKey' },
      { label: 'Secret key', name: 'secret' },
    ],
  },
  'cex:okx-v2': {
    name: 'OKX',
    symbol: 'OKX',
    icon: OKXLogo,
    iconBackground: commonBlack,
    adminOnly: true,
    extraFields: [
      { label: 'Password', name: 'password', type: 'password' },
      { label: 'API key', name: 'apiKey' },
      { label: 'Secret key', name: 'secret' },
    ],
  },
  'cex:aplo': {
    name: 'APLO',
    symbol: 'APLO',
    icon: AploLogo,
    iconBackground: commonBlack,
    extraFields: [
      { label: 'API key', name: 'apiKey' },
      { label: 'Account id', name: 'accountId' },
    ],
  },
  'cex:aplo-v2': {
    name: 'APLO',
    symbol: 'APLO',
    icon: AploLogo,
    iconBackground: commonBlack,
    adminOnly: true,
    extraFields: [
      { label: 'API key', name: 'apiKey' },
      { label: 'Account id', name: 'accountId' },
    ],
  },
  'cex:deribit': {
    name: 'Deribit',
    symbol: 'Deribit',
    icon: DeribitLogo,
    iconBackground: commonBlack,
    extraFields: [
      { label: 'API key', name: 'apiKey' },
      { label: 'Secret key', name: 'secret' },
    ],
  },
  'cex:deribit-v2': {
    name: 'Deribit',
    symbol: 'Deribit',
    icon: DeribitLogo,
    iconBackground: commonBlack,
    adminOnly: true,
    extraFields: [
      { label: 'API key', name: 'apiKey' },
      { label: 'Secret key', name: 'secret' },
    ],
  },
  'cex:bitget': {
    name: 'Bitget',
    symbol: 'Bitget',
    icon: BitgetLogo,
    iconBackground: commonBlack,
    extraFields: [
      { label: 'API key', name: 'apiKey' },
      { label: 'Secret key', name: 'secret' },
      { label: 'Passphrase', name: 'password', type: 'password' },
    ],
  },
  'cex:huobi': {
    name: 'Huobi',
    symbol: 'Huobi',
    icon: HuobiLogo,
    iconBackground: commonBlack,
    extraFields: [
      { label: 'API key', name: 'apiKey' },
      { label: 'Secret key', name: 'secret' },
    ],
    adminOnly: true,
  },
  'cex:kucoinfutures': {
    name: 'Kucoin Futures',
    symbol: 'Kucoin',
    icon: KucoinLogo,
    iconBackground: commonBlack,
    extraFields: [
      { label: 'API key', name: 'apiKey' },
      { label: 'Secret key', name: 'secret' },
      { label: 'Password', name: 'password', type: 'password' },
    ],
    adminOnly: true,
  },
  'cex:gateio': {
    name: 'Gate.io',
    symbol: 'GateIO',
    icon: GateioLogo,
    iconBackground: commonWhite,
    extraFields: [
      { label: 'API key', name: 'apiKey' },
      { label: 'Secret key', name: 'secret' },
    ],
  },
  'cex:falconx': {
    name: 'Falcon X',
    symbol: 'FalconX',
    icon: FalconXLogo,
    iconBackground: commonBlack,
    extraFields: [
      { label: 'API key', name: 'apiKey' },
      { label: 'Secret key', name: 'secret' },
      { label: 'Password', name: 'password', type: 'password' },
    ],
  },
  'cex:falconx-v2': {
    name: 'Falcon X',
    symbol: 'FalconX',
    icon: FalconXLogo,
    iconBackground: commonBlack,
    adminOnly: true,
    extraFields: [
      { label: 'API key', name: 'apiKey' },
      { label: 'Secret key', name: 'secret' },
      { label: 'Password', name: 'password', type: 'password' },
    ],
  },
  'cex:debank': {
    name: 'EVM',
    blockchain: true,
    symbol: 'ETH',
    icon: EthereumLogo,
    iconBackground: '#687fed',
    extraFields: [{ label: 'Public id', name: 'publicId' }],
    // empty disclaimer. By default, if the property isn't defined, we show
    // information about ip whitelisting, but it's not needed here
    disclaimer: (): ReactElement => <></>,
  },
  'cex:debank-v2': {
    name: 'EVM',
    blockchain: true,
    symbol: 'EVM',
    icon: EthereumLogo,
    iconBackground: '#687fed',
    adminOnly: true,
    extraFields: [{ label: 'Public id', name: 'publicId' }],
    // empty disclaimer. By default, if the property isn't defined, we show
    // information about ip whitelisting, but it's not needed here
    disclaimer: (): ReactElement => <></>,
  },
  'cex:copper': {
    name: 'Copper',
    symbol: 'Copper',
    icon: CopperLogo,
    iconBackground: commonBlack,
    extraFields: [
      { label: 'API key', name: 'apiKey' },
      { label: 'Secret key', name: 'secret' },
    ],
  },
  'cex:bybit': {
    name: 'Bybit',
    symbol: 'Bybit',
    icon: BybiLogo,
    iconBackground: commonBlack,
    extraFields: [
      { label: 'API key', name: 'apiKey' },
      { label: 'Secret key', name: 'secret' },
    ],
  },
  'cex:phemex': {
    name: 'Phemex',
    symbol: 'Phemex',
    icon: PhemexLogo,
    iconBackground: commonBlack,
    extraFields: [
      { label: 'ID', name: 'apiKey' },
      { label: 'API Secret', name: 'secret' },
    ],
  },
  'cex:fireblocks': {
    name: 'Fireblocks',
    symbol: 'Fireblocks',
    icon: FireblocksLogo,
    iconBackground: commonBlack,
    extraFields: [
      { label: 'API key', name: 'apiKey' },
      { label: 'Private key', name: 'secret' },
    ],
    disclaimer: () => (
      <>
        <DefaultIPWhitelistingSection />
        Please be aware that transaction information provided by Fireblocks might be incomplete.
      </>
    ),
  },
  'cex:solana': {
    name: 'Solana',
    blockchain: true,
    symbol: 'Solana',
    icon: SolanaLogo,
    iconBackground: commonBlack,
    extraFields: [{ label: 'Public id', name: 'publicId' }],
    // empty disclaimer. By default, if the property isn't defined, we show
    // information about ip whitelisting, but it's not needed here
    disclaimer: (): ReactElement => <></>,
  },
  'cex:bitquery_btc': {
    name: 'Bitcoin',
    blockchain: true,
    symbol: 'Bitcoin',
    icon: BitcoinLogo,
    iconBackground: commonBlack,
    extraFields: [{ label: 'Public id', name: 'publicId' }],
    // empty disclaimer. By default, if the property isn't defined, we show
    // information about ip whitelisting, but it's not needed here
    disclaimer: (): ReactElement => <></>,
  },
  'cex:dydx': {
    name: 'DyDx',
    blockchain: true,
    symbol: 'DyDx',
    icon: DyDxLogo,
    iconBackground: commonBlack,
    extraFields: [
      { label: 'Passphrase', name: 'password', type: 'password' },
      { label: 'API key', name: 'apiKey' },
      { label: 'Secret key', name: 'secret' },
    ],
    // empty disclaimer. By default, if the property isn't defined, we show
    // information about ip whitelisting, but it's not needed here
    disclaimer: (): ReactElement => <></>,
  },
  [GENIE_VENUE]: {
    name: 'Virtual',
    symbol: 'Virtual',
    icon: unknownVenue.icon,
    iconBackground: unknownVenue.iconBackground,
  },
  [unknownVenueLabel]: unknownVenue,
};

export const venues: Record<string, VenueEntry> = mapValues(
  (obj): VenueEntry => ({
    disclaimer: DefaultIPWhitelistingSection,
    ...obj,
  }),
  rawVenues
);
